import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import "../../styles/sidebat.css"
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const currentRole = JSON.parse(localStorage.getItem("user")).roleName

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  // const getNavStyleSubMenuClass = (navStyle) => {
  //   if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
  //     return "gx-no-header-submenu-popup";
  //   }
  //   return "";
  // };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars style={{ color: "#fff" }} className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'lite'}
            mode="inline">

            <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-dasbhoard" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>

            {
              (currentRole === "SUPER_ADMIN" || currentRole === "ADMIN") &&
              <Menu.Item key="merchants">
                <Link to="/merchants"><i className="icon icon-home" />
                  <span>Manage Merchants</span>
                </Link>
              </Menu.Item>
            }

            <Menu.SubMenu
              title={<span><i className="icon icon-money" /><span>Transactions</span></span>}
            >
              <Menu.Item key="transactions/all">
                <Link to="/transactions/all">
                  <span>All</span></Link>
              </Menu.Item>
              <Menu.Item key="transactions/collections">
                <Link to="/transactions/collections">
                  <span>Collections</span></Link>
              </Menu.Item>
              <Menu.Item key="transactions/disbursements">
                <Link to="/transactions/disbursements">
                  <span>Disbursements</span></Link>
              </Menu.Item>
            </Menu.SubMenu>

            {(currentRole === "SUPER_ADMIN" || currentRole === "ADMIN") &&
              <Menu.Item key="operators">
                <Link to="/operators"><i className="icon icon-lock-screen" />
                  <span>Operators</span>
                </Link>
              </Menu.Item>}


            {currentRole !== "USER" &&
              <>

                <Menu.Item key="merchant-reference">
                  <Link to="/merchant-reference"><i className="icon icon-notification" />
                    <span>Merchant Reference</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="wallet">
                  <Link to="/wallet"><i className="icon icon-bitcoin" />
                    <span>Wallet</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="wallet-history">
                  <Link to="/wallet-history"><i className="icon icon-notification" />
                    <span>Wallet History</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="users">
                  <Link to="/users"><i className="icon icon-profile" />
                    <span>Users</span>
                  </Link>
                </Menu.Item>
              </>}

            {/*<Menu.SubMenu key="settings" popupClassName={getNavStyleSubMenuClass(navStyle)}*/}
            {/*              title={<span><i className="icon icon-setting"/><span>System Settings</span></span>}>*/}
            {/*  <Menu.Item key="settings/tracker-modals">*/}
            {/*    <Link to="/settings/tracker-modals">*/}
            {/*      <span>Trackers Models</span></Link>*/}
            {/*  </Menu.Item>*/}
            {/*  <Menu.Item key="setting/api-configs">*/}
            {/*    <Link to="/setting/api-configs">*/}
            {/*      <span>API Configurations</span></Link>*/}
            {/*  </Menu.Item>*/}

            {/*  <Menu.Item key="setting/commission-configs">*/}
            {/*    <Link to="/setting/commission-configs">*/}
            {/*      <span>Commission Configurations</span></Link>*/}
            {/*  </Menu.Item>*/}
            {/*</Menu.SubMenu>*/}

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);


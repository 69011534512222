import React from 'react';

const HidePageByRoleNameContext = React.createContext(undefined);
HidePageByRoleNameContext.displayName = "HidePageByRoleNameContext";


export const useHideObjectBasedOnRoleNameContext = () => {
  const context = React.useContext(HidePageByRoleNameContext);

  if (!context) {
    throw new Error("HidePageByRoleNameContext must be within the provider")
  }

  return context;
}

export default  HidePageByRoleNameContext;

import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import HidePageByRoleNameContext from "../context/HidePageByRoleName"
import { useSelector } from "react-redux";
const App = ({ match }) => {
  const [allowed, setAllowed] = useState(false);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const currentPathname = initURL;
  // console.warn("reducer", authUser, initURL);
  const user_roles = authUser.role
  const user_role = localStorage.getItem('user');


  useEffect(() => {
    let mounted = true;

    async function checkUserPermission() {
      // load roles object and current pathname
      // const matchedObject = user_roles?.find((obj) => obj.name === currentPathname)
      let matchedObject;
      const objects = JSON.parse(user_roles);

      for (let i = 0; i < objects.length; i++) {
        if (objects[i].name === currentPathname) {
          matchedObject = objects[i];
        }
      }

      // check permission if allowed
      const user_type = JSON.parse(user_role);


      if (user_type.roleName === "ADMIN") {
        setAllowed(true)
      }
      else {
        setAllowed(!!matchedObject);
      }
    }

    if (mounted && currentPathname && user_roles) {
      checkUserPermission();
    }

    return () => {
      mounted = false;
    }

  }, [user_roles, currentPathname]);

  return (

    <div className="gx-main-content-wrapper">
      {/* <HidePageByRoleNameContext.Provider> */}
        <Switch>
          <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
          <Route path={`${match.url}merchants/:id`} component={asyncComponent(() => import('./Merchants/customerDetails'))} />
          <Route path={`${match.url}merchants`} component={asyncComponent(() => import('./Merchants'))} />
          <Route path={`${match.url}add-merchant`} component={asyncComponent(() => import('./Merchants/addCustomer'))} />
          <Route path={`${match.url}agents`} component={asyncComponent(() => import('./Agent'))} />
          <Route path={`${match.url}transactions/details/:id`} component={asyncComponent(() => import('./Transactions/components/transactionDetails'))} />
          <Route path={`${match.url}transactions/all`} component={asyncComponent(() => import('./Transactions'))} />
          <Route path={`${match.url}transactions/disbursements`} component={asyncComponent(() => import('./Transactions/disbursement'))} />
          <Route path={`${match.url}transactions/collections`} component={asyncComponent(() => import('./Transactions/collections'))} />
          <Route path={`${match.url}operators`} component={asyncComponent(() => import('./Operators'))} />
          <Route path={`${match.url}trackers`} component={asyncComponent(() => import('./Tracker'))} />
          <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))} />
          <Route path={`${match.url}settings/tracker-modals`} component={asyncComponent(() => import('./TrackersModal'))} />
          <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))} />
          <Route path={`${match.url}wallet/:id`} component={asyncComponent(() => import('./Wallet/walletDetails'))} />
          <Route path={`${match.url}wallet`} component={asyncComponent(() => import('./Wallet'))} />
          <Route path={`${match.url}wallet-history`} component={asyncComponent(() => import('./Wallet/walletHistory'))} />
          <Route path={`${match.url}merchant-reference`} component={asyncComponent(() => import('./MerchantReference'))} />
        </Switch>
      {/* </HidePageByRoleNameContext.Provider> */}
    </div>
  )
};

export default App;
